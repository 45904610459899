import useLiveChat from "@hooks/useLiveChat";


const LiveChatWidget = () => {

    const {
        InitializeLiveChatWidget
    } = useLiveChat();


    return (
        <InitializeLiveChatWidget
            licenseId={process.env.NEXT_PUBLIC_LIVE_CHAT_LICENSE_ID}
        />
    );

};


export default LiveChatWidget;
