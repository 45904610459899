import { createContext, useCallback, useMemo, useContext, useState } from "react";


const SiteConfigContext = createContext({});


export function SiteConfig({children}) {

    const allowedStorageKeys = useMemo(() => ({
        "phone": "833.872.4467",
        "address": [
            "3210 Lake Emma Rd. Suite 3090",
            "Lake Mary, FL, 32746"
        ]
    }), []);


    const [configuration, setConfiguration] = useState(allowedStorageKeys);


    const filterConfiguration = useCallback((data, callback) => {
        const filteredData = Object.assign({}, configuration);
        const [dataKeys, persistentFieldKeys] = [Object.keys(data), Object.keys(configuration)];
        const [first, next] = dataKeys.length > persistentFieldKeys.length
            ? [persistentFieldKeys, data]
            : [dataKeys, filteredData];

        first.filter((field) => field in next).map((key) => {
            if (key in allowedStorageKeys) {
                filteredData[key] = data[key];
            } else {
                delete filteredData[key];
            }
        });

        callback(filteredData);

    }, [allowedStorageKeys, configuration]);


    const storeConfiguration = useCallback((data) => {
        setConfiguration(data);
    }, []);


    const updateConfiguration = useCallback((data) => {
        filterConfiguration(data, storeConfiguration);
    }, [filterConfiguration, storeConfiguration]);


    const resetConfiguration = () => {
        filterConfiguration(allowedStorageKeys, storeConfiguration);
    };


    return (
        <SiteConfigContext.Provider
            value={{
                configuration,
                methods: {
                    updateConfiguration,
                    resetConfiguration
                }
            }}
        >{ children }</SiteConfigContext.Provider>
    );

}


export function useSiteConfig() {
    return useContext(SiteConfigContext);
}


export default function withSiteConfig(Component) {

    return function WithSiteConfigValues({ ...props }) {
        const themeItems = useSiteConfig();
        return (
            <Component {...props} {...themeItems}/>
        );
    };

}