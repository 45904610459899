import {useCallback, useMemo} from "react";
import {LiveChatWidget as LiveChat} from "@livechat/widget-react";


const useLiveChat = () => {

    const VISIBILITY_HIDDEN = "hidden";

    const HIDE_WIDGET = "hide";
    const MINIMIZE_WIDGET = "minimize";
    const MAXIMIZE_WIDGET = "maximize";

    const VISIBILITY_OPTIONS = useMemo(() => [
        HIDE_WIDGET,
        MINIMIZE_WIDGET,
        MAXIMIZE_WIDGET
    ], [HIDE_WIDGET, MAXIMIZE_WIDGET, MINIMIZE_WIDGET]);


    const displayLiveChat = () => {
        return process.env.NEXT_PUBLIC_LIVE_CHAT_LICENSE_ID;
    };


    const updateLiveChatVisibility = useCallback((option) => {
        if (VISIBILITY_OPTIONS.includes(option)) {
            LiveChatWidget.call(option);
        }
    }, [VISIBILITY_OPTIONS]);


    const setLiveChatSessionVariables = useCallback((data) => {
        data = data || {};
        LiveChatWidget.call("set_session_variables", data);
    }, []);


    const updateLiveChatSessionVariables = useCallback((data) => {
        data = data || {};
        LiveChatWidget.call("update_session_variables", data);
    }, []);


    const updateLiveChatCustomerName = useCallback((data) => {
        data = data || "";
        LiveChatWidget.call("set_customer_name", data);
    }, []);


    const updateLiveChatCustomerEmail = useCallback((data) => {
        data = data || "";
        LiveChatWidget.call("set_customer_email", data);
    }, []);


    const InitializeLiveChatWidget = (props) => {

        const {
            licenseId = null
        } = props;


        return licenseId ? (
            <LiveChat
                license={licenseId}
                visibility={VISIBILITY_HIDDEN}
                // onVisibilityChanged={_handleVisibilityChange}
            />
        ) : "";

    };


    return {
        displayLiveChat,
        InitializeLiveChatWidget,
        updateLiveChatVisibility,
        setLiveChatSessionVariables,
        updateLiveChatSessionVariables,
        updateLiveChatCustomerName,
        updateLiveChatCustomerEmail
    };

};


export default useLiveChat;