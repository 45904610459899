import {SiteConfig} from "../resources/context/SiteConfig";
import Script from "next/script";
import "@sass"
import LiveChatUpScope from "@components/general/LiveChatUpScope";
import LiveChatWidget from "@components/general/LiveChatWidget";


function MyApp({Component, pageProps}) {
    return (
        <SiteConfig>
            {process.env.NEXT_STATIC_ENV === "live" ? (
                <>
                    <Script
                        strategy={"beforeInteractive"}
                        src="https://cdn.cookielaw.org/consent/7c38a354-1c38-4c53-9894-c7485924b73f/OtAutoBlock.js" type="text/javascript"
                    />
                    <Script
                        strategy={"beforeInteractive"}
                        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" charSet="UTF-8" data-domain-script="7c38a354-1c38-4c53-9894-c7485924b73f"
                    />
                </>
            ) : (
                <>
                    <Script
                        strategy={"beforeInteractive"}
                        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" charSet="UTF-8" data-domain-script="7c38a354-1c38-4c53-9894-c7485924b73f-test"
                    />
                </>
            )}


            <Script
                strategy={"beforeInteractive"}
                dangerouslySetInnerHTML={{__html: `function OptanonWrapper() {
            
                if (OnetrustActiveGroups.indexOf('C0002') != -1) { 
                    gtag('consent', 'update', {'analytics_storage': 'granted'})
                } else {
                    gtag('consent', 'update', {'analytics_storage': 'denied'})
                }
            
                if (OnetrustActiveGroups.indexOf('C0004') != -1){ 
                    gtag('consent', 'update', {'ads_storage': 'granted'})
                } else {
                    gtag('consent', 'update', {'ads_storage': 'denied'})
                }
                
            }`}}
            />

            <Script
                strategy={"beforeInteractive"}
                dangerouslySetInnerHTML={{
                __html: `<!-- Start VWO Async SmartCode -->

                (function(){requestAnimationFrame(function hideIfRequired(){if(window._vwo_code){var hidingStyle=hideIfRequired.hidingStyle=document.getElementById('_vis_opt_path_hides')||hideIfRequired.hidingStyle;if(!window._vwo_code.finished()&&!_vwo_code.libExecuted){if(!document.getElementById('_vis_opt_path_hides') && hidingStyle){

                    document.getElementsByTagName('head')[0].appendChild(hidingStyle)}

                    requestAnimationFrame(hideIfRequired)}}})})()


                window._vwo_code = window._vwo_code || (function() {
                    var account_id = 536037,
                    settings_tolerance = 5000,
                    library_tolerance = 5000,
                    use_existing_jquery = false,
                    is_spa = 1,
                    hide_element = 'body',
                    f = false,
                    d = document,
                    code = {
                        use_existing_jquery: function() {
                            return use_existing_jquery;
                        },
                        library_tolerance: function() {
                            return library_tolerance;
                        },
                        finish: function() {
                            if (!f) {
                                f = true;
                                var a = d.getElementById('_vis_opt_path_hides');
                                if (a) a.parentNode.removeChild(a);
                            }
                        },
                        finished: function() {
                            return f;
                        },
                        load: function(a) {
                            var b = d.createElement('script');
                            b.src = a;
                            b.type = 'text/javascript';
                            b.innerText;
                            b.onerror = function() {
                                _vwo_code.finish();
                            };
                            d.getElementsByTagName('head')[0].appendChild(b);
                        },
                        init: function() {
                            window.settings_timer = setTimeout('_vwo_code.finish()', settings_tolerance);
                            var a = d.createElement('style'),
                                b = hide_element ? hide_element + '{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}' : '',
                                h = d.getElementsByTagName('head')[0];
                            a.setAttribute('id', '_vis_opt_path_hides');
                            a.setAttribute('type', 'text/css');
                            if (a.styleSheet) a.styleSheet.cssText = b;
                            else a.appendChild(d.createTextNode(b));
                            h.appendChild(a);
                            this.load('https://dev.visualwebsiteoptimizer.com/j.php?a=' + account_id + '&u=' + encodeURIComponent(d.URL) + '&f=' + (+is_spa) + '&r=' + Math.random());
                            return settings_timer;
                        }
                    };
                    window._vwo_settings_timer = code.init();
                    return code;
                }());`}}
            />

            <Script
                strategy={"beforeInteractive"}
                src={"https://maps.googleapis.com/maps/api/js?key=" + process.env.GOOGLE_MAPS_KEY + "&libraries=places"}
            />

            <LiveChatWidget/>

            <LiveChatUpScope/>

            <Component {...pageProps} />

        </SiteConfig>
    );
}


export default MyApp;